import React from "react"
import Layout from "./layout"
import OwnerStatsTable from "./owner-stats-table"
import HeadToHead from "./head-to-head"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

function OwnerHeadToHead({ owner, allOwnerNames }) {
  const headToHead = JSON.parse(owner.headToHead)
  const classes = useStyles()

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>-</TableCell>
            {allOwnerNames.map(opponent => (
              <TableCell key={opponent}>{opponent}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow hover>
            <TableCell>{owner.name}</TableCell>
            {allOwnerNames.map(opponent => (
              <TableCell key={owner.name + opponent}>
                <HeadToHead
                  name1={owner.name}
                  name2={opponent}
                  data={headToHead[opponent]}
                />
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default function Owner({ pageContext }) {
  const { owner, allOwnerNames } = pageContext
  const teams = owner.teams.items.sort((a, b) => a.season > b.season)

  return (
    <Layout>
      <h1>{owner.name}</h1>
      <h2>Summary</h2>
      <OwnerStatsTable items={[owner]} showName={true} />
      <h2>Seasons</h2>
      <OwnerStatsTable items={teams} showSeason={true} />
      <h2>Head to Head Results</h2>
      <OwnerHeadToHead owner={owner} allOwnerNames={allOwnerNames} />
    </Layout>
  )
}
